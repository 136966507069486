import { ref, onMounted, inject, reactive, provide } from "vue";
import axios from "axios";
import { Dataset } from "../types";
import {dataset, translations} from "../store";

/**
 * Helper to return translated text.
 * @param text string
 */
export default (text: string) => {
  //@ts-ignore
  if (translations.value !== undefined && translations.value[text] !== undefined) {
    //@ts-ignore
    return translations.value[text];
  }
  return text;
};