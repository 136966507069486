import axios from "axios";
import { ref } from "vue";
import { Dataset } from "../types";
import {dataset} from "../store";
import { notify } from "@kyvg/vue3-notification";

/**
 * Requests the nonce for the user to sign.
 * @param address
 */
export default async function (address: string) {
  return await axios
    .post(dataset.value.apiUrl + "mtwc-auth/generate-nonce",{address: address}).catch((error) => notify({title: error.response.data.message, type: 'error'}));
}
