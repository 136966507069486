import {ethers} from "ethers";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import {dataset} from "../store";

export default async function () {

    const provider = await EthereumProvider.init({
        projectId: dataset.value.projectId,
        showQrModal: true,
        chains: [1], //need to have at least one, so I set mainnet chainId
        //@ts-ignore
        optionalChains: dataset.value.chains.split(",").map((r) => parseInt(r)),
    });

    await provider.enable();

    const web3Provider = new ethers.providers.Web3Provider(provider);
    const network = await web3Provider.getNetwork();

    return {
        provider: web3Provider,
        signer: web3Provider.getSigner(),
        currentNetwork: ethers.utils.hexlify(network.chainId),
        canSwitch: false
    };
}
