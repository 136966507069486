import {watch, ref, toRaw} from "vue";
import { Dataset } from "../types";
import getNonce from "./getNonce";
//@ts-ignore
import Web3 from "web3/dist/web3.min.js";
import popMeWallet from "./popMeWallet";
import {ethers} from "ethers";

export default async function (address: string)  {
      // @ts-ignore
    const {data} = await getNonce(address);
    const {provider, currentNetwork} = await popMeWallet();

    const msg = data.payload.nonce;
    let prefix = "\x19Ethereum Signed Message:\n" + msg.length

    const signedMessage = await provider.send(
        'personal_sign',
        [ ethers.utils.hexlify(ethers.utils.toUtf8Bytes(msg)), address ]
    );

    return signedMessage;
}
