import axios from "axios";
import {inject, ref, watch} from "vue";
import {Dataset} from "../types";
import getSignature from "./getSignature";
import {dataset, loading} from "../store";
import {notify} from "@kyvg/vue3-notification";
import doLogin from "./doLogin";

/**
 * Register new account with wallet.
 * @param address
 * @param currentNetwork
 * @param signatureR
 */
export default function (address: string, currentNetwork: string, signatureR ?: string): any {
    const data = ref();
    const loaded = ref<boolean>(false);
    const error = ref(false);

    const run = async () => {
        const signature = signatureR !== undefined ? signatureR : await getSignature(address);

        axios
            .post(dataset.value.apiUrl + "mtwc-auth/register", {
                signature: signature,
                address: address,
                network: currentNetwork,
            })
            .then(({data: responseData}) => {
                data.value = responseData;
                loaded.value = true;
                notify({title: responseData.message, type: 'success'})
                setTimeout(() => (dataset.value.registerRedirect === '' ? window.location.reload() : window.location.assign(dataset.value.registerRedirect)), 3000);
            })
            .catch((error) => {

                const l = doLogin(address, currentNetwork, signatureR);
                l.run();

                notify({title: error.response.data.message, type: 'error'})
            }).finally(() => loading.value = false)
    }

    return {run, data, loaded};
}
