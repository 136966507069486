import { createApp, provide } from 'vue'
import App from './App.vue'
import {dataset} from "./store";
import Notifications from '@kyvg/vue3-notification'

const elems = document.querySelectorAll("[id^='wallet-connect-']");

/** @ts-ignore */
for (const elem of elems) {

    dataset.value = elem.dataset;

    const app = createApp(App, {
        currentState: elem.dataset.currentState
    });


    app.use(Notifications)
    app.mount(elem);
}
