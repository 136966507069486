import { ref, inject, watch } from "vue";
import axios from "axios";
import { Dataset } from "../types";
import getSignature from "./getSignature";
import {dataset, loading} from "../store";
import {notify} from "@kyvg/vue3-notification";

/**
 * Link account with wallet.
 * @param address
 * @param currentNetwork
 */
export default function (address: string, currentNetwork: string) {
  const loaded = ref<boolean>(true);
  const data = ref();

  //we use eth_accounts because it returns a list of addresses owned by us.
  const run = async () => {
    const signature = await getSignature(address);

        axios
          .post(
            dataset.value.apiUrl + "mtwc-auth/link",
            {
              // @ts-ignore
              network: currentNetwork,
              address: address,
              signature: signature
            },
            {
              // @ts-ignore
              headers: { "X-WP-Nonce": dataset.value.nonce },
            }
          )
          .then(({ data: responseData }) => {
            data.value = responseData;
            loaded.value = false;
            notify({title: responseData.message, type: 'success'})

            setTimeout(() => window.location.reload(), 3000);
          })
          .catch((error) => notify({title: error.response.data.message, type: 'error'})).finally(()=> loading.value = false)
  };

  return {
    loaded,
    run,
    data,
  };
}
