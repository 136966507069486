import {ref, inject, watch} from "vue";
import axios from "axios";
import {Dataset} from "../types";
import getSignature from "./getSignature";
import {dataset, loading} from "../store";
import {notify} from "@kyvg/vue3-notification";
import doRegister from "./doRegister";

/**
 * Login account with wallet.
 * @param address
 * @param currentNetwork
 * @param signatureR
 */
export default function (address: string, currentNetwork: string, signatureR ?: string): any {
    const loaded = ref<boolean>(true);
    const data = ref();
    const error = ref(false);
    const run = async () => {
        const signature = signatureR !== undefined ? signatureR : await getSignature(address);
        await axios
            .post(dataset.value.apiUrl + "mtwc-auth/login", {
                signature: signature,
                address: address,
                // @ts-ignore
                network: currentNetwork,
            })
            .then(({data: responseData}) => {
                data.value = responseData;
                loaded.value = true;
                notify({title: responseData.message, type: 'success'})

                setTimeout(() => (window.location.href = dataset.value.loginRedirect === '' ? dataset.value.siteUrl : dataset.value.loginRedirect), 3000);
            })
            .catch((error) => {

                const r = doRegister(address, currentNetwork, signatureR);
                r.run();

                notify({title: error.response.data.message, type: 'error'})
            }).finally(() => loading.value = false)
    }


    return {
        loaded,
        data,
        run,
    };
}
