<script setup lang="ts">
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
import {ref, onMounted, inject, reactive, provide, watch, onBeforeMount, useAttrs} from "vue";
import axios from "axios";
import { Dataset} from "./types";
import getTranslations from "./functions/makeTranslation";
import {dataset, loading, translations} from "./store";
import getButtonConfigs from "./functions/getButtonConfigs";
import {notify} from "@kyvg/vue3-notification";
import ButtonWalletConnect from "./components/ButtonWalletConnect.vue";
import popMeWallet from "./functions/popMeWallet";

const props = defineProps<{
  currentState: string;
}>();

let translationsLoaded = ref<boolean>(false);
const isConfigured= ref<boolean>(true);

onBeforeMount(() => {
  axios
      .get(dataset.value.apiUrl + "mtwc-auth/translations")
      .then(({data: responseData}) => {
        translations.value = responseData;
        translationsLoaded.value = true;
      })
      .catch(() => notify({title: "Could not load translations!", type: 'error'}));
  //@ts-ignore
  isConfigured.value = dataset.value.projectId !== "" && dataset.value.chains !== "";
})

const buttonConfig = getButtonConfigs(props.currentState);

const runAction = async () => {
  const {signer,currentNetwork} = await popMeWallet();
  const action = buttonConfig.toRun(await signer.getAddress(), currentNetwork);
  loading.value = true;
  await action.run();
};

</script>

<template>
  <notifications />
  <div class="woocommerce-page" v-if="translationsLoaded">

    <button-wallet-connect
        :buttonText="isConfigured ? getTranslations(buttonConfig.text) : 'ERROR: Missing Project ID or No Chains were specified!'"
        :isHyperlink="buttonConfig.isHyperlink"
        :hyperlink="buttonConfig.hyperlink"
        :state="buttonConfig.state"
        :loading="loading"
        @runButtonAction="runAction"
    ></button-wallet-connect>
  </div>
</template>

<style>
.vue-notification-group {
  top:20px !important;
}

</style>
