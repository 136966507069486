import { ButtonConfig, Dataset } from "../types";
import login from "./doLogin";
import link from "./doLink";
import unlink from "./doUnlink";
import register from "./doRegister";
import {dataset} from "../store";

/**
 * Get the settings for the button.
 * @param currentState
 */
export default function (currentState : string) {
  let buttonConfig: ButtonConfig = {
    text: "login",
    isHyperlink: false,
    recheckPermissionsBeforeAction: true,
    state: false,
    toRun: login,
  };

  if (currentState === "link") {
    buttonConfig = {
      text: "link",
      isHyperlink: false,
      recheckPermissionsBeforeAction: true,
      state: false,
      toRun: link,
    };
  } else if (currentState === "unlink") {
    buttonConfig = {
      text: "unlink",
      isHyperlink: false,
      recheckPermissionsBeforeAction: true,
      state: false,
      toRun: unlink,
    };
  } else if (currentState === "register") {
    buttonConfig = {
      text: "register",
      isHyperlink: false,
      recheckPermissionsBeforeAction: true,
      state: false,
      toRun: register,
    };
  }

  return buttonConfig;
}
