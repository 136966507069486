import {ref} from "vue";
import {Dataset, Response} from "./types";

// Cast empty object as dataset because :
// 1. this field gets rewritten only once.
// 2. the store is populated before mounting the app.

// Only shared state can be stored inside the dataset. Like API URL / current user etc.
export const dataset = ref<Dataset>({} as Dataset);

export const translations = ref<Object[]>();

export const loading = ref<boolean>(false);

